import Vue from "vue";
import Vuex from "vuex";

import liveData from "@/store/Modules/liveData";
import CycleStore from "@/services/cycle/cycle.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    liveData,
    CycleStore
  }
});
