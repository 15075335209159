import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import validateTravel from "./routerGuard";

Vue.use(VueRouter);

// const routes: Array<RouteConfig> = [
//   {
//     path: "/",
//     name: "Home",
//     component: Home,
//   },
//   {
//     path: "/about",
//     name: "About",
//     component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
//   }
// ];

const routes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/login"
  },
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login/Login.vue"),
    meta: { guestOnly: true }
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Home/Home.vue"),
    meta: { requireAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue"
          ),
        meta: { requireAuth: true }
      },
      {
        path: "/employees",
        name: "Employees",
        component: () =>
          import(
            /* webpackChunkName: "employees" */ "@/views/Employees/Employees.vue"
          ),
        meta: { requireAuth: true }
      },
      {
        path: "/employees/add",
        name: "Add Employee",
        component: () =>
          import(
            /* webpackChunkName: "addEmployee" */ "@/views/AddEmployee/AddEmployee.vue"
          ),
        meta: { requireAuth: true }
      },
      {
        path: "/employee/:id",
        name: "Employee",
        component: () =>
          import(
            /* webpackChunkName: "employees" */ "@/views/Employee/Employee.vue"
          ),
        meta: { requireAuth: true }
      },
      {
        path: "/groups",
        name: "Groups",
        component: () =>
          import(/* webpackChunkName: "groups" */ "@/views/Groups/Groups.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/customers",
        name: "Customers",
        component: () =>
          import(
            /* webpackChunkName: "customers" */ "@/views/Customers/Customers.vue"
          ),
        meta: { requireAuth: true }
      },
      {
        path: "/customer/:id",
        name: "Customer",
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/views/Customer/Customer.vue"
          ),
        meta: { requireAuth: true }
      },
      {
        path: "/report",
        name: "Report",
        component: () =>
          import(/* webpackChunkName: "report" */ "@/views/Report/Report.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/cycles",
        name: "Cycles",
        component: () =>
          import(/* webpackChunkName: "report" */ "@/views/Cycles/Cycles.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/search",
        name: "Search",
        component: () =>
          import(/* webpackChunkName: "report" */ "@/views/Search/Search.vue"),
        meta: { requireAuth: true }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  validateTravel(to, from, next);
});

export default router;
