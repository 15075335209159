import CycleStore from "@/services/cycle/cycle.store";
import { CycleDTO } from "@/services/cycle/cycle.dto";

import liveData from "@/store/Modules/liveData";

// export default function validateNavigation(to: any, from: any, next: any): any {
//   const stringifiedCurrentCycle = window.localStorage.getItem("currentCycle");

//   if (stringifiedCurrentCycle) {
//     const currentCycle = JSON.parse(stringifiedCurrentCycle);
//     CycleStore.state = currentCycle;
//   }

//   console.log(to, from, next);
//   console.log("CycleStore", CycleStore);

//   if (from.path !== to.path) {
//     const cycle: CycleDTO = CycleStore.state;

//     if (to.meta.requireAuth && liveData.state.isLoggedIn) {
//       if (cycle.cycleId === "") {
//         next("/home");
//       } else {
//         next();
//       }
//     } else {
//       next("/login");
//     }
//   }
// }

export default function validateTravel(to: any, from: any, next: any): any {
  const stringifiedCurrentCycle = window.localStorage.getItem("currentCycle");

  if (stringifiedCurrentCycle) {
    const currentCycle = JSON.parse(stringifiedCurrentCycle);
    CycleStore.state = currentCycle;
  }

  const cycle: CycleDTO = CycleStore.state;
  console.log(
    `Going from ${from.path} to ${to.path} which ${(to.meta.requireAuth &&
      `requires authentication and user is ${
        liveData.state.isLoggedIn
          ? "logged in."
          : "not logged in hence going to login page."
      }`) ||
      (to.meta.guestOnly &&
        `is guest only screen and user is ${
          liveData.state.isLoggedIn
            ? "logged in hence going to home page."
            : "not logged in."
        }`)}`
  );

  if (to.path === from.path) return;

  if (to.meta.guestOnly) {
    if (liveData.state.isLoggedIn) {
      next("/home");
    } else {
      next();
    }
  } else {
    if (to.meta.requireAuth) {
      if (liveData.state.isLoggedIn) {
        if (cycle.cycleId === "" && to.path !== "/home") {
          next("/home");
        } else {
          next();
        }
      } else {
        next("/login");
      }
    } else {
      next("/login");
    }
  }
}
