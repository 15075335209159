function initialState() {
  return {
    loading: false,
    selectedActivity: 1,
    statusMessage: "Success!",
    statusDuration: 2000,
    statusType: "success",
    snackbar: false,
    isLoggedIn: false,
    user: ""
  };
}

const state = {
  loading: false,
  selectedActivity: 0,
  selectedActivityName: "",
  statusMessage: "Success!",
  statusDuration: 2000,
  statusType: "success",
  snackbar: false,
  isLoggedIn: false,
  user: ""
};

const getters = {
  // getters for state
  getLoading: (state: any) => state.loading,
  getSelectedActivity: (state: any) => state.selectedActivity,
  getSelectedActivityName: (state: any) => state.selectedActivityName,
  getStatusMessage: (state: any) => state.statusMessage,
  getStatusDuration: (state: any) => state.statusDuration,
  getStatusType: (state: any) => state.statusType,
  getIsLoggedIn: (state: any) => state.isLoggedIn,
  getUser: (state: any) => state.user
};

const mutations = {
  // mutations for state
  setLoading: (state: any, value: any) => {
    state.loading = value;
  },
  setSelectedActivity: (state: any, value: any) => {
    state.selectedActivity = value;
  },
  setSelectedActivityName: (state: any, value: any) => {
    state.selectedActivityName = value;
  },
  setStatusMessage: (state: any, value: any) => {
    state.statusMessage = value;
  },
  setStatusDuration: (state: any, value: any) => {
    state.statusDuration = value;
  },
  setStatusType: (state: any, value: any) => {
    state.statusType = value;
  },
  setSnackbar: (state: any, value: any) => {
    state.snackbar = value;
  },
  setIsLoggedIn: (state: any, value: any) => {
    state.isLoggedIn = value;
  },
  setUser: (state: any, value: any) => {
    state.user = value;
  },
  reset(state: any) {
    const s = initialState();
    state = s;
    console.log(state);
  }
};

const actions = {
  // actions for state
  setLoading: ({ commit, state }: any, value: any) => {
    commit("set_loading", value);
    return state.message;
  },
  setSelectedActivity: ({ commit, state }: any, value: any) => {
    commit("set_selectedActivity", value);
    return state.message;
  }
};

export default {
  // exports the vuex object of "institutesData" store
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
