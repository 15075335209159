import Vue from "vue";
import Vuetify from "vuetify/lib";

import App from "@/App.vue";
import router from "@/router";
import store from "@/services/Store";

Vue.config.productionTip = false;
Vue.config.errorHandler = function(err, vm, info) {
  console.log(err, vm, info);
};
Vue.use(Vuetify);
const vuetify = new Vuetify({});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
