// import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

// import { CycleDTO } from "./cycle.dto";

// const Namespace = "Cycle";

// @Module({ namespaced: true, name: "Cycle" })
// class CycleModule extends VuexModule {
//   public cycle: CycleDTO = ;

//   get currentCycle() {
//     return this.cycle;
//   }

//   @Mutation
//   public setCycle(cycleDetails: CycleDTO): void {
//     this.cycle = cycleDetails;
//   }

//   @Mutation
//   public reset(): void {
//     this.cycle = {
//       cycleId: "",
//       name: "",
//       isActive: false,
//       assignedOn: new Date(),
//       dueOn: new Date()
//     };
//   }

//   @Action
//   public upateCycle(cycleDetails: CycleDTO): void {
//     this.context.commit("setCycle", cycleDetails);
//   }
// }

import { CycleDTO } from "./cycle.dto";

function initialState() {
  return {
    cycleId: "",
    name: "",
    isActive: false,
    assignedOn: new Date(),
    dueOn: new Date()
  };
}

const state: CycleDTO = {
  cycleId: "",
  name: "",
  isActive: false,
  assignedOn: new Date(),
  dueOn: new Date()
};

const getters = {
  isActive: (state: CycleDTO) => state.isActive,
  name: (state: CycleDTO) => state.name
};

const mutations = {
  setCycle: (state: CycleDTO, value: CycleDTO) => {
    state.cycleId = value.cycleId;
    state.name = value.name;
    state.isActive = value.isActive;
    state.assignedOn = value.assignedOn;
    state.dueOn = value.dueOn;
  },
  reset(state: CycleDTO) {
    const s: CycleDTO = initialState();
    state = s;
    console.log(state);
  }
};

const actions = {
  setCycle: ({ commit, state: CycleDTO }: any, value: CycleDTO) => {
    commit("setCycle", value);
    return state;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
